.site-foot {
  &__background {
    @apply absolute bottom-0 inset-x-0 w-full z-10 overflow-hidden;
  }

  &__container {
    @apply py-7 relative z-20;
  }

  &__heading {
    @apply text-xl mb-6;
  }

  &__main {
    @apply bg-black text-white rounded-3xl px-6 sm:px-12 xl:px-24 py-12 grid gap-12 md:gap-12;

    grid-template:
      "who-made"
      "policies"
      "partners"
      "separator"
      "social"
      "copyright"
      "people"
      /1fr;

    @screen md {
      grid-template:
        "who-made policies"
        "partners people"
        "separator separator"
        "social social"
        "copyright copyright"
        /1fr 1fr;
    }

    @screen xl {
      grid-template:
        "who-made policies partners people"
        "separator separator separator separator"
        "social social copyright copyright"
        /2fr 2fr 2fr 1fr;
    }
  }

  &__who-made {
    grid-area: who-made;
  }

  &__policies {
    grid-area: policies;
  }

  &__partners {
    grid-area: partners;
  }

  &__people {
    grid-area: people;
    @apply mx-auto md:ml-0 xl:ml-auto xl:mr-0;
  }

  &__separator {
    grid-area: separator;
  }

  &__social {
    grid-area: social;
    @apply text-center xl:text-left;
  }

  &__copyright {
    grid-area: copyright;
    @apply text-center xl:text-right uppercase text-xs md:text-sm tracking-wider;
  }
}

