.sticker {
  @apply absolute -translate-x-1/2 -translate-y-1/2 transition-transform;
  @apply hover:scale-105;
  width: clamp(140px, 20%, 290px);

  svg {
    @apply w-full h-auto;
  }
}


.sticker-map {
  width: clamp(140px, 15%, 220px) !important;
}
