.landing-homepage-hero {
  &__heading {
    @apply heading text-3xl sm:text-4xl md:text-5xl 2xl:text-6xl text-center;
    @media all and (max-height: 1024px) {
      @apply text-5xl;
    }

    @media all and (max-width: 768px) and (max-height: 1024px) {
      @apply text-3xl;
    }
  }
}
