.cta-button {
  @apply leading-none text-sm font-[700] flex items-center justify-center no-underline whitespace-nowrap;
  @apply px-7 py-4 rounded-full;
  @apply transition-opacity;

  background-color: var(--site-primary-color);

  &:not(:disabled) {
    @apply cursor-pointer;

    &:hover {
      @apply opacity-75;
    }
  }

  &--inverted {
    @apply bg-white;
  }

  &--outline {
    @apply border-brand-ur-black border-2 bg-transparent;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
