.site-head {
  @apply fixed top-0 inset-x-0 z-30 px-4 xl:px-16 py-6 md:py-10;

  &__burger {
    @apply cursor-pointer bg-black rounded-full inline-flex items-center justify-center text-white relative;
    width: 2.75rem;
    height: 2.75rem;
    @screen md {
      width: 3.5rem;
      height: 3.5rem;
    }
    overflow: hidden;
    transition: all 0.4s linear;

    &-line {
      @apply bg-white h-0.5 w-5;
      position: absolute;
      left: 50%;
      transform-origin: center;
      transition: all 0.3s ease, background-color 0.4s linear;

      &:nth-child(1) {
        top: calc(50% - 0.5rem);
        transform: translateX(-50%);
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(3) {
        top: calc(50% + 0.5rem);
        transform: translateX(-50%);
      }
    }

    &--open {
      @apply bg-white;

      .site-head__burger-line {
        @apply bg-black;
        transition: all 0.3s ease, background-color 0.4s linear;

        &:nth-child(1) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  &__logo {
    @apply block;

    &-image {
      @apply block w-64 h-auto md:w-auto;
    }
  }
}
