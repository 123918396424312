.entry-grid {
  @apply -mx-4 relative flex flex-wrap justify-start;

  &__item {
    @apply px-4 mb-8 relative;

    &:nth-child(n+1):nth-last-child(-n+1),
    &:nth-child(n+1):nth-last-child(-n+1)~& {
      @apply mb-0;
    }

    @for $i from 1 through 12 {

      &.w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i}),
      &.w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i})~& {
        @media (max-width: 739px) {
          @apply mb-0;
        }
      }

      &.sm\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i}),
      &.sm\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i})~& {
        @media (min-width: 640px) and (max-width: 767px) {
          @apply mb-0;
        }
      }

      &.md\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i}),
      &.md\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i})~& {
        @media (min-width: 768px) and (max-width: 1023px) {
          @apply mb-0;
        }
      }

      &.lg\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i}),
      &.lg\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i})~& {
        @media (min-width: 1024px) and (max-width: 1279px) {
          @apply mb-0;
        }
      }

      &.xl\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i}),
      &.xl\:w-1\/#{$i}:nth-child(#{$i}n+1):nth-last-child(-n+#{$i})~& {
        @media (min-width: 1280px) {
          @apply mb-0;
        }
      }
    }
  }

  &__no-results {
    @apply text-center text-lg w-full mb-32;

    @screen md {
      @apply mb-64;
    }
  }
}
