.site-card {
  $root: &;

  @apply rounded-3xl overflow-hidden flex flex-col h-full justify-between no-underline;

  &--disabled {
    @apply bg-gray-200;
  }

  &:hover {
    .site-card__cta-button {
      opacity: .75;
    }
  }

  &__image-wrapper {
    @apply bg-brand-ur-cream h-52 flex items-center justify-center;
  }

  &__image {
    @apply object-cover h-full w-full;
  }

  &__content {
    @apply p-6 md:p-10 flex flex-col flex-grow;

    &--disabled {
      @apply bg-gray-200;
    }

    &--active {
      @apply bg-brand-light-brown;
    }

    &-text {
      @apply flex-grow;
    }

    &-area {
      @apply text-sm uppercase tracking-widest mb-2;
    }

    &-title {
      @apply heading text-xl mb-2;
    }

    &-body {
      @apply mb-6 md:mb-12 text-sm md:text-md;
    }

    &-cta-button {
      @apply mt-auto;
    }
  }
}
