.article-card {
  $root: &;

  @apply relative flex flex-col justify-between overflow-hidden no-underline h-full rounded-xl;

  &--featured {
    @screen md {
      @apply md:flex-row;

      #{$root}__image-holder {
        @apply w-1/3 relative;
      }

      #{$root}__image {
        @apply absolute left-0 top-0 w-full h-full object-cover;
      }

      #{$root}__content {
        @apply w-2/3 p-8;
      }

      #{$root}__content-cta-button {
        @apply mt-32;
      }

      #{$root}__content-heading {
        @apply text-2xl;
      }
    }
  }

  &--with-bg-colour {
    #{$root}__content {
      @apply bg-brand-ur-orange;
    }

    #{$root}__content-cta-button {
      @apply bg-white border-none;
    }

    &:hover {
      #{$root}__content-cta-button {
        background-color: white !important;
        opacity: 0.8 !important;
      }
    }
  }

  &:hover {
    #{$root}__content-cta-button {
      background-color: var(--site-primary-color);
    }
  }

  &__image {
    @apply w-full;
  }

  &__content {
    @apply p-6 bg-white flex-1 flex flex-col justify-between;

    &-date {
      @apply mb-2;
    }

    &-heading {
      @apply text-xl;
    }

    &-summary {
      @apply text-sm md:text-md;
    }

    &-cta-button {
      @apply mt-8 bg-transparent;
      &:hover {
        opacity: 1 !important;
      }
      transition: all 0.1s linear !important;
    }
  }
}
