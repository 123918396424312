.have-your-say {
  @apply rounded-3xl w-full bg-brand-ur-cream px-6 py-10 sm:px-10 sm:py-16 lg:px-20 mx-auto my-2.5 md:my-7 flex flex-col gap-6 md:gap-12;

  &__items {
    @apply flex flex-col lg:flex-row gap-4 md:gap-10 items-stretch;
  }

  &__item {
    @apply flex-1 flex flex-col justify-between gap-y-4 border-b md:border-b-0 pb-8 md:pb-0 border-black last:border-b-0 last:pb-0;

    &__heading {
      @apply text-xl leading-none;
    }
  }

  &__separator {
    @apply w-[1px] bg-black;
  }
}
