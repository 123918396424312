.quote {
  @apply rounded-3xl text-center md:text-left px-6 md:px-24 py-8 flex flex-col md:flex-row items-center gap-7 md:gap-24;

  &__image {
    @apply w-36 sm:w-48 mx-auto;
  }

  &__quote {
    @apply text-lg font-[600] leading-[1.3] mb-7 whitespace-normal;
  }

  &__attribution {
    @apply font-heading text-xl not-italic;
  }
}
