#elc-cookie-consent {
  @apply rounded-3xl p-6 max-w-none w-auto #{!important};
  left: 1em !important;

  @screen md {
    @apply text-black left-auto right-4 transform -translate-x-0 #{!important};
    max-width: calc(100% - 6em) !important;
    width: 30em !important;
  }

  h3 {
    @apply text-xl font-heading leading-[0.85] mb-2 #{!important};
    font-weight: 400 !important;
  }

  .elc-header-description {
    @apply mb-2;
  }

  .elc-text-link {
    @apply text-black #{!important};
  }

  .elc-button-link {
    @apply bg-black rounded-full font-[700] #{!important};
  }

  .elc-secondary-button-link {
    @apply border-black text-black rounded-full font-[700] #{!important};
  }

  .button-container {
    @apply flex gap-1 justify-center;

    @screen md {
      @apply justify-end;
    }
  }
}

#elc-cookie-tab {
  @apply bg-white #{!important};
  @apply hidden;
}

#elc-tab-link {
  @apply text-black #{!important};
}

.cookie-policy #elc-cookie-tab {
  @apply block;
}
