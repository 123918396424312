.contact-block {
  @apply rounded-3xl max-w-5xl p-6 sm:p-20 lg:px-32 mx-auto my-2.5 md:my-7;
  @apply flex flex-col md:flex-row gap-7 md:gap-24;

  &__image {
    @apply w-24 sm:w-36 mx-auto;
  }

  &__heading {
    @apply text-2xl md:text-3xl lg:text-4xl mb-2.5;
  }
}
