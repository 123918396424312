.filters {
  @apply mb-6 pt-4 flex flex-col md:flex-row justify-between items-center gap-x-8 gap-y-4 md:gap-y-0;

  @screen md {
    @apply pt-0;
  }

  &__list-container {
    @apply relative;
  }

  &__form {
    @apply flex flex-col justify-start items-center -mx-2;

    @screen md {
      @apply flex-row;
    }
  }

  &__fieldset {
    @apply text-sm px-2 pb-2 w-full;

    @screen md {
      @apply mb-0 pt-2;
    }

    &--sectors {
      @screen md {
        @apply w-1/2;
      }

      @screen lg {
        @apply w-1/4;
      }
    }

    &--services {
      @screen md {
        @apply w-1/2;
      }
    }
  }

  &__list {
    @apply flex flex-wrap list-none flex-row items-center py-0 justify-center md:justify-start gap-x-2 gap-y-4;
  }

  &__list-item {
    @apply relative cursor-pointer inline-block w-auto;

  }

  &__link {
    @apply text-sm text-black rounded px-4 py-1 cursor-pointer font-[700] no-underline;
    box-shadow: 0 0 0 2px black;

    &:hover, &:focus {
      @apply bg-gray-200;
    }

    &.active {
      @apply bg-black text-white;
    }
  }


}

.filter-grid__error-message {
  @apply text-red-500 text-center mb-8;
}
