.accordion {
  @apply flex flex-col items-center my-12;

  &__button {
    @apply flex items-center justify-between w-full cursor-pointer font-normal font-heading leading-[0.85] text-lg md:text-xl xl:text-2xl;
  }

  &__panel {
    @apply w-full overflow-hidden max-h-0;
    transition: max-height 0.4s ease-out;

    &[aria-hidden="true"] {
      @apply max-h-0;
    }
  }

  .icon {
    @apply w-4 h-4;
  }
}
