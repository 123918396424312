$hamburger-layer-border-radius: 0;
$hamburger-layer-color: #ffffff;
$hamburger-layer-width: 28px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 7px;
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

$slick-dot-size: 10px;
$slick-opacity-default: 1;
$slick-dot-color-alt: color(white);
$slick-arrow-color: black;
$slick-prev-character: "\F104" !default;
$slick-next-character: "\F105" !default;
$slick-dot-character: "\F111" !default;
$slick-font-family: 'Font Awesome 5 Pro';
$slick-loader-path: "/images/";
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';

@import '../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css';
@import '../../node_modules/@fortawesome/fontawesome-pro/css/solid.css';
@import '../../node_modules/@fortawesome/fontawesome-pro/css/brands.css';

@tailwind base;

@import "base/fonts";

@import "elements/page";
@import "elements/links";
@import "elements/lists";
@import "elements/headings";

@import "objects/container";
@import "objects/content-wrap";
@import "objects/content";
@import "objects/prose";

@tailwind components;
@import "components/accordion";
@import "components/article";
@import "components/article-card";
@import "components/article-section";
@import "components/carousel";
@import "components/cta-button";
@import "components/comments";
@import "components/contact-block";
@import "components/cookie-consent";
@import "components/embed";
@import "components/entry-grid";
@import "components/filters";
@import "components/have-your-say";
@import "components/heading";
@import "components/homepage-intro";
@import "components/intro";
@import "components/landing-homepage-hero";
@import "components/map";
@import "components/map-intro";
@import "components/pagination-controls";
@import "components/preheading";
@import "components/primary-nav";
@import "components/quote";
@import "components/rounded-section";
@import "components/site-card";
@import "components/site-head";
@import "components/site-foot";
@import "components/social-icons";
@import "components/sticker";
@import "components/text-and-image-callout";
@import "components/text-block";
@import "components/text-card";
@import "components/voting";

@tailwind utilities;

@import "utilities/site-colours";

@layer utilities {
  .container {
    @apply mx-auto px-5;
    max-width: 1252px;
  }

  .prose {
    p {
      @apply first-of-type:mt-0 last-of-type:mb-0;
    }

    h1,
    h2,
    h3 {
      @apply font-normal uppercase tracking-[0.2em] mb-8;
    }

    a {
      @apply hover:no-underline;
    }
  }
}
