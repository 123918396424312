.map-intro {
  @apply fixed inset-0 z-40 flex items-center justify-center;
  transition: opacity 0.3s ease;

  &__overlay {
    @apply absolute inset-0 bg-black bg-opacity-50 z-20;
  }

  &__content {
    @apply max-w-4xl p-4 z-30 relative;
    @apply rounded-3xl w-full;
    @apply px-6 py-10 sm:px-10 sm:py-20 lg:px-20;
    @apply mx-4 my-7 lg:mb-24;
    @apply grid gap-x-8 md:gap-x-16;

    grid-template:
      "copy"
      "image"
      "cta";

    @screen lg {
      grid-template:
        "image copy"
        "cta cta" /
        1fr 3fr;
    }

    @screen xl {
      grid-template:
        "image copy"
        "image cta" /
        1fr 3fr;
    }
  }

  &__image-container {
    grid-area: image;
    @apply relative flex-1 flex-shrink-0 mt-8 md:-mt-10;
  }

  &__image {
    @apply w-1/2 mx-auto;

    @screen lg {
      @apply absolute block top-0 -left-56 max-w-none;
      width: calc(100% + theme("spacing.64"));
    }

    @screen xl {
      @apply top-auto -bottom-32 translate-y-0;
    }
  }

  &__copy {
    grid-area: copy;
  }

  &__heading {
    @apply text-left text-2xl sm:text-3xl md:text-4xl mb-2.5;
  }
}
