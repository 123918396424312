.bg-site-colour {
  background-color: var(--site-primary-color);
}

.text-site-colour {
  color: var(--site-primary-color);
}

.text-site-colour-dark {
  color: var(--site-primary-color-dark);
}

.border-site-colour {
  border-color: var(--site-primary-color);
}
