body,
html {
  @apply bg-brand-grey scroll-smooth;
}

body {
  @apply overflow-x-hidden;
}


// breakpoints (for use with media-query-listener npm package)
body:after {
  display: none;
  content: 'xs';

  @screen sm {
    content: 'sm';
  }

  @screen md {
    content: 'md';
  }

  @screen lg {
    content: 'lg';
  }

  @screen xl {
    content: 'xl';
  }

  @screen 2xl {
    content: '2xl';
  }
}

.default-layout {
  @apply bg-brand-grey min-h-screen flex flex-col font-body font-[500] text-sm md:text-base relative;
}

.body-copy {
  p {
    margin-bottom: 1.25em;
  }

  p:last-of-type {
    margin-bottom: 0px;
  }
}

