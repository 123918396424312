
@font-face {
  font-family: "Bebas Neue";
  src: url("/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-display: swap;
}

*,
*::after,
*::before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
