.article {
  &--standard-top-margin {
    @apply mt-36 md:mt-48;
  }

  &--reduced-top-margin {
    @apply mt-24;
    @screen md {
      margin-top: 8.5rem;
    }
  }
}
