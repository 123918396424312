.pagination-controls {
  @apply text-sm my-16 text-center font-[700];

  &__button {
    @apply px-2 py-1 border-2 border-black cursor-pointer no-underline rounded mx-1;
  }

  &__page {
    @apply px-2 py-1 text-black no-underline border-2 border-black rounded mx-1;

    &.active {
      @apply px-2 py-1 border-2 border-black text-white bg-black;
    }
  }
}
