.proxi-map-wrapper {
  @apply relative w-full h-full overflow-hidden;
  height: calc(100vh - 96px);

  @screen md {
    height: calc(100vh - 136px);

  }

  iframe {
    @apply w-full h-full border-0 relative;
    height: calc(100% + 42px);
    top: -42px;

    @screen md {
      height: calc(100% + 50px);
      top: -50px;
    }
  }

  div {
    @apply hidden;
  }
}
