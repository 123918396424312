.homepage-intro {
  @apply flex flex-col-reverse lg:flex-row gap-x-8 md:gap-x-16;

  &__image {
    @apply w-1/2 mx-auto;

    @screen lg {
      @apply absolute block -left-36 max-w-none top-auto -bottom-32 translate-y-0;
      width: calc(100% + theme("spacing.40"));
    }

    @screen xl {
      @apply top-auto -bottom-32 translate-y-0;
    }

    &-container {
      @apply hidden lg:block relative lg:w-1/6 xl:w-1/4 mt-8 md:mt-0;
    }
  }

  &__text-container {
    @apply lg:w-5/6 xl:w-3/4;
  }

  &__heading {
    @apply text-left;
  }
}
