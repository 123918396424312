.text-and-image-callout {

  @apply relative flex flex-col justify-between overflow-hidden no-underline h-full rounded-xl;

  @screen md {
    @apply flex-row;
    &__image-holder {
      @apply w-1/3 relative;
    }
    &__image {
      @apply absolute left-0 top-0 w-full h-full object-cover;
    }
    &__content {
      @apply w-2/3 p-8 bg-brand-ur-orange;
    }
    &__cta-button {
      @apply mt-32;
    }
    &__heading {
      @apply text-2xl;
    }
  }

  &__image {
    @apply w-full;
  }

  &__content {
    @apply p-6 md:p-12 bg-brand-ur-cream flex-1 flex flex-col justify-between;
  }

  &__preheading {
    @apply mb-2;
  }

  &__heading {
    @apply text-xl;
  }

  &__body {
    @apply text-sm md:text-md;
  }

  &__cta-button {
    @apply mt-8;
  }
}
