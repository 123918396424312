/* necessary due to a bug with tailwind typography
https://github.com/tailwindlabs/tailwindcss-typography/issues/228 */

.prose-standard.prose-invert {
  --tw-prose-body: theme('colors.white');
  --tw-prose-headings: theme('colors.white');
  --tw-prose-lead: theme('colors.white');
  --tw-prose-links: theme('colors.white');
  --tw-prose-bold: theme('colors.white');
  --tw-prose-invert-body: theme('colors.black');
  --tw-prose-invert-headings: theme('colors.black');
  --tw-prose-invert-lead: theme('colors.black');
  --tw-prose-invert-links: theme('colors.black');
  --tw-prose-invert-bold: theme('colors.black');
}
