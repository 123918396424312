.carousel {
  &.slick-dotted {
    .carousel__slide {
      @apply mb-2;

      @screen md {
        @apply mb-4;
      }
    }
  }

  .slick-list,
  .slick-slide,
  a {
    outline: none !important;
  }

  .slick-prev,
  .slick-next {
    @apply top-auto z-20;
    bottom: -30px;

    &:before,
    &:after {
      font-size: 36px;
    }
  }

  .slick-prev {
    @apply left-0;
  }

  .slick-next {
    @apply right-0;
  }

  .slick-dots {
    bottom: -30px;

    .slick-active {
      button:before {
        font-weight: 900;
      }
    }
  }
}

.carousel--equal-heights {
  .slick-track {
    @apply flex;
  }

  .slick-slide {
    @apply flex h-auto justify-center items-center;
  }
}

/* Specific carousel type styling */

.carousel--cards {
  @apply relative;


  &:before, &:after {
    @apply block absolute top-0 bottom-0 w-16 z-10;
    content: '';
  }

  &:before {
    @apply -left-6 w-7;
    background: linear-gradient(to right, rgba(240, 240, 240, var(--tw-bg-opacity)) 0%, rgba(240, 240, 240, var(--tw-bg-opacity)) 25%, rgba(240, 240, 240, 0) 100%);
  }

  &:after {
    @apply -right-6 w-16;
    background: linear-gradient(to left, rgba(240, 240, 240, var(--tw-bg-opacity)) 0%, rgba(240, 240, 240, var(--tw-bg-opacity)) 75%, rgba(240, 240, 240, 0) 100%);
  }

  .slick-list {
    @apply pr-16 -mx-2;
  }

  .slick-slide {
    @apply mx-2 relative z-20;
  }
}

.carousel--image {
  position: relative;
  width: 100%;
  height: 100%;

  &.slick-dotted {
    .carousel__slide {
      @apply mb-0;
    }
  }

  .slick-dots {
    bottom: -30px;

    @screen md {
      bottom: -34px;
    }
  }

  .slick-slide {
    @apply px-2;
  }
}

.carousel--show-overflow {
  .slick-list {
    @apply overflow-visible;
  }
}

// add this for your caption
.caption-container {
  @apply relative h-12 overflow-hidden text-sm mt-2;
}
