.primary-nav {
  @apply fixed left-0 top-0 w-full h-full z-20 pointer-events-none;

  &.open {
    @apply pointer-events-auto;
  }

  &__overlay {
    @apply fixed inset-0 bg-black bg-opacity-20;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__menu {
    @apply absolute inset-y-0 right-0 w-full lg:w-2/3 xl:w-1/2;
    @apply bg-black text-white lg:rounded-l-3xl;
    transition: transform 0.3s ease;

    &__container {
      @apply absolute w-full inset-y-0;
      @apply flex flex-col justify-between gap-4;
      @apply pb-6 lg:pb-20 pt-24 sm:pt-36 pr-6 pl-8 md:pl-10 xl:pl-24 xl:pr-16;
      @apply text-right pointer-events-auto;
      right: 15px;
      @screen xl {
        max-width: 768px;
      }
    }

    &__menus, &__footer-wrapper {
      @apply flex flex-col gap-y-6 md:gap-y-12;

      @media all and (max-height: 1024px) {
        @apply gap-y-6;
      }
    }

    a {
      @apply no-underline;
    }

    &__separator {
      @apply border-white border-opacity-25;
    }

    &__primary {
      @apply font-heading text-xl sm:text-3xl 2xl:text-4xl leading-[0.95];
    }

    &__secondary {
      @apply font-heading text-lg sm:text-xl 2xl:text-2xl leading-[0.95];
    }

    &__footer {
      @apply w-full flex flex-col items-end gap-5;
    }

    @media all and (max-width: 767px) and (max-height: 1024px) {
      &__primary {
        font-size: 2.5rem !important;
      }

      &__secondary {
        font-size: 1.875rem !important;
      }
    }

    @media all and (min-width: 768px) and (max-height: 1024px) {
      &__primary {
        font-size: 4.375rem !important;
      }

      &__secondary {
        font-size: 2.5rem !important;
      }
    }
  }

  &__who-made-this {
    @apply max-w-sm text-xs 2xl:text-sm body-copy;

    @media all and (max-height: 1024px) {
      font-size: 0.75rem !important;
    }
  }
}
