.cc-w {
  .cc-ll-i::before {
    content: '·' !important;
  }

  .cc-i-content {
    @apply relative;
  }

  .cc-i-footer {
    @apply absolute right-0 top-0;
  }

  .cc-f-wrap .cc-f-btn {
    @apply leading-none text-sm font-[700] flex items-center justify-center no-underline;
    @apply px-7 py-4 rounded-full;
    @apply transition-opacity;
    @apply bg-white text-black capitalize border-0 float-right;

      &:not(:disabled) {
      @apply cursor-pointer;

      &:hover {
        @apply opacity-75;
      }
    }

  }

  .cc-v-count {
    @apply inline-block bg-white text-black px-2 rounded-full;
  }

  .cc-v-up, .cc-v-down {
    @apply relative;
    top: 1px;
  }

  .cc-ll-i a {
    @apply text-gray-500;
  }
}
